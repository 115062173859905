const colors = {
  black: '#000000',
  white: '#FFFFFF',
  eggRed: '#E4022C',
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#1A202C',
    900: '#171923',
  },
  alpha: {
    50: '#F1F9FF',
    100: '#CEDCE8',
    200: '#A7C0D5',
    300: '#7FA3C1',
    400: '#5887AE',
    500: '#306A9A',
    600: '#26557B',
    700: '#1D405C',
    800: '#132A3E',
    900: '#0A151F',
  },
  beta: {
    50: '#FEEFE9',
    100: '#FCE0D4',
    200: '#FAC0A9',
    300: '#F7A17D',
    400: '#F58152',
    500: '#F26227',
    600: '#C24E1F',
    700: '#913B17',
    800: '#612710',
    900: '#301408',
  },
  gamma: {
    50: '#F5FAF6',
    100: '#EAF5EC',
    200: '#D5EBDA',
    300: '#C1E1C7',
    400: '#ACD7B5',
    500: '#97CDA2',
    600: '#7DAC86',
    700: '#638B6B',
    800: '#486B4F',
    900: '#2E4A34',
  },
  delta: {
    50: '#EBF8FF',
    100: '#BEE3F8',
    200: '#90CDF4',
    300: '#63B3ED',
    400: '#4299E1',
    500: '#3182CE',
    600: '#2B6CB0',
    700: '#2C5282',
    800: '#2A4365',
    900: '#1A365D',
  },
  epsilon: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  zeta: {
    50: '#FFFDF6',
    100: '#FFF8E5',
    200: '#FFF5DC',
    300: '#FFF1CB',
    400: '#FFECB9',
    500: '#FFE7A8',
    600: '#E5CB86',
    700: '#CCAF65',
    800: '#B29443',
    900: '#8C6A11',
  },
};

export default colors;
